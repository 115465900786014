import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CoursePayments.scss";
import { Input } from "components/Form";
import { BiRupee } from "react-icons/bi";
import {
  Link,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from "react-router-dom";
import UploadDoc from "dashboard/edit-profile/UploadDoc";
import { toast } from "react-hot-toast";
import { userProfile } from "services/dashboard";
import { getCoupons } from "services/courses/coupons";
import { getBillingCourseDetailById } from "services/courses/courses";
import useButtonLoader from "hooks/useButtonLoader";
// import Payment from "./section/Payment";
import { useAddons } from "context/context";
import CourseSubscriptionSkeleton from "components/skeleton/CourseSubscriptionSkeleton";
import { FaTimesCircle } from "react-icons/fa";
import { errorResponse, failResponse } from "config/config";
import { CourseBookImg } from "assets/images";
import Payment from "./section/PaymentLinkPayment";
import { getPaymentLinkUserDetails } from "services/studentcoursepayments";
import PaymentLinkPayment from "./section/PaymentLinkPayment";

export default function StudentCoursePayments() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encryptedData = queryParams.get('params');

  const { courseId, courseName,url } = useParams();
  const navType = useNavigationType();
  const navigate = useNavigate();
  const [couponToggle, setCouponToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [couponList, setCouponList] = useState([]);

  const { addons } = useAddons();
  const [buttonRef, setSubmitLoading] = useButtonLoader("Apply");
  const [buttonRef2, setButtonLoading2] = useButtonLoader("Proceed to payment");

  const [isEditable, setIsEditable] = useState({
    profile: false,
    idProof: false,
    addressProof: false,
  });

  const [userData, setUserData] = useState({});
  const [courseDetails, setCourseDetails] = useState({});

  const [errorField, setErrorField] = React.useState({
    firstName: "",
    lastName: "",
    Mobile: "",
    EmergencyContactNo: "",
    Education: "",
    PHouseNo: "",
    PStreet: "",
    PLandmark: "",
    PCity: "",
    PState: "",
    PZipCode: "",
    THouseNo: "",
    TStreet: "",
    TLandmark: "",
    TCity: "",
    TState: "",
    TZipCode: "",
  });

  const [isSameAddress, setIsSameAddress] = React.useState(
    userData.PHouseNo === userData.THouseNo &&
      userData.PStreet === userData.TStreet &&
      userData.PLandmark === userData.TLandmark &&
      userData.PCity === userData.TCity &&
      userData.PState === userData.TState &&
      userData.PZipCode === userData.TZipCode
  );

  //*********************Handel Validation********************************** */
  const handleValidation = () => {
    let errorField = {};
    let formIsValid = true;

    if (!userData.firstName) {
      errorField["firstName"] = "Please Enter First Name";
      formIsValid = false;
    }
    if (!userData.lastName) {
      errorField["lastName"] = "Please Enter Last Name";
      formIsValid = false;
    }

    if (!userData.Mobile) {
      errorField["Mobile"] = "Please Enter Mobile No.";
      formIsValid = false;
    }
    if (!userData.EmailID) {
      errorField["EmailID"] = "Please Enter Email ID";
      formIsValid = false;
    }
    if (!userData.EmergencyContactNo) {
      errorField["EmergencyContactNo"] = "Please Enter Emergency No.";
      formIsValid = false;
    }
    if (!userData.Education) {
      errorField["Education"] = "Please Enter Education";
      formIsValid = false;
    }

    if (!userData.PHouseNo) {
      errorField["PHouseNo"] = "Please Enter House No.";
      formIsValid = false;
    }
    if (!userData.PStreet) {
      errorField["PStreet"] = "Please Enter Street Name";
      formIsValid = false;
    }
    if (!userData.PLandmark) {
      errorField["PLandmark"] = "Please Enter Landmark";
      formIsValid = false;
    }
    if (!userData.PCity) {
      errorField["PCity"] = "Please Enter City";
      formIsValid = false;
    }
    if (!userData.PState) {
      errorField["PState"] = "Please Enter State";
      formIsValid = false;
    }
    if (!userData.PZipCode) {
      errorField["PZipCode"] = "Please Enter Zipcode";
      formIsValid = false;
    }

    if (!userData.THouseNo) {
      errorField["THouseNo"] = "Please Enter House No.";
      formIsValid = false;
    }
    if (!userData.TStreet) {
      errorField["TStreet"] = "Please Enter Street Name";
      formIsValid = false;
    }
    if (!userData.TLandmark) {
      errorField["TLandmark"] = "Please Enter Landmark";
      formIsValid = false;
    }
    if (!userData.TCity) {
      errorField["TCity"] = "Please Enter City";
      formIsValid = false;
    }
    if (!userData.TState) {
      errorField["TState"] = "Please Enter State";
      formIsValid = false;
    }
    if (!userData.TZipCode) {
      errorField["TZipCode"] = "Please Enter Zipcode";
      formIsValid = false;
    }

    if (isEditable.idProof) {
      formIsValid = false;
    }

    if (isEditable.addressProof) {
      formIsValid = false;
    }

    setErrorField(errorField);
    return formIsValid;
  };

// Fetch Payment Details
const getPaymentDetails = async () => {
  try {
    const res = await getPaymentLinkUserDetails({ encryptedstring: encryptedData });
    if (res.code === 200) {
      if (res.data?.userdetails?.paymentstatus === "Pending") {
        setUserData({ ...res.data?.userdetails } || {});
      }else{
        navigate("/payment-link-expired")
      }
    } else {
      failResponse(res);
    }
  } catch (err) {
    errorResponse(err);
  }
};

// Fetch Course Details when userData.courseid is available
useEffect(() => {
  if (!userData.courseid) return; // Ensure courseid exists before making API call

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://nodesrapi.shubhraranjan.com/api/v1/client/get-payment-link-course-details?courseid=${userData.courseid}`,
        {
          headers: {
            API_KEY: "qaw8AxBFDXzJLbhMJyTOmkoFGLlagVUY",
          },
        }
      );

      if (response.data.code === 200) {
        const { billingdetails } = response.data.data;
        setCourseDetails(billingdetails);
      } else if (response.data.code === 100) {
        console.error("Invalid parameters or data");
        navigate("/payment-link-expired");
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };

  fetchData();
}, [userData.courseid]); // Dependency on userData.courseid

// Get User Data when component mounts
React.useLayoutEffect(() => {
  getPaymentDetails();
  // eslint-disable-next-line
}, [flag]);

  return (
    <>
      {/* <div className="breadcrumb">
        <div className="container">
          <ul>
            <li>
              <Link to="/course-listing">Course listing</Link>
            </li>
            <li>
              <Link to={`/course-detail/${courseName}/${courseId}`}>
                Course details
              </Link>
            </li>
            <li>
              <Link to="#">Payment summary</Link>
            </li>
          </ul>
        </div>
      </div> */}

      <section className="payment-details spacing">
        <div className="container">
          {/* loading component */}
          {/* {loading && <CourseSubscriptionSkeleton />} */}

          {/* main component */}
          {!loading && (
            <div className="subscription_content">
              <div className="course_detail">
                <h4>Payment Details</h4>
                <div className="form_group">
                  {/* {+courseDetails?.purchased === 1 ? (
                    <>
                      <div className="already_purchased">
                        <img src={CourseBookImg} alt="" loading="lazy" />
                        <h4>You have already purchased this course.</h4>
                      </div>
                    </>
                  ) : (
                    <> */}
                      <h5>Student Details</h5>
                      <ul className="student-details">
                        <li>
                          {userData?.firstname || ""}&nbsp;{userData?.lastname||""}
                          </li>
                        <li>{userData?.email || ""}</li>
                        <li>{userData?.mobile || ""}</li>
                      </ul>
                      <h5>Course Details</h5>
                      <ul className="course_bill_summary">
                        <li>
                            {/* <span>Course Name : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> */}
                            <b>{userData?.coursetitle || ""}</b>
                        </li>
                        <li>
                            {/* <span> </span> */}
                            <b>Course Fees : <BiRupee />{userData?.amount || "0.00"}</b>
                        </li>
                        {userData.discount &&<li>
                            {/* <span></span> */}
                            <b>Discount : <BiRupee />{userData?.discount || "0.00"}</b>
                        </li>}
                      </ul>
                      <ul className="course_bill_summary">
                        {/* <li>
                          <span>Admission Center :</span>{" "}
                          <b>{courseDetails.admissionCenter}</b>
                        </li> */}
                        {/* <li>
                          <span>Course Fees :</span>
                          {courseDetails.isOldStudent === 1 ? (
                            <del>
                              <b>
                                <BiRupee /> {courseDetails.courseFee}
                              </b>
                            </del>
                          ) : (
                            <b>
                              <BiRupee /> {courseDetails.courseFee}
                            </b>
                          )}
                        </li> */}

                        {/* <li>
                              <span>Conveyance fee :</span>
                              <b>
                                <BiRupee />
                                {courseDetails.conveyanceFee}
                              </b>
                            </li>
                             <li>
                          <span>GST ({courseDetails.gst}%) :</span>
                          <b>
                            <BiRupee />
                            {courseDetails.gstFee}
                          </b>
                        </li> */}

                        {/* context addon display */}
                        {addons[courseId] && addons[courseId]?.length > 0 && (
                          <div className="my-2">
                            <h6 className="">Addons</h6>
                            {addons[courseId]?.map((addon) => {
                              return (
                                <li key={addon.id}>
                                  <span>{addon.name || "N/A"} :</span>
                                  <b>
                                    <BiRupee />
                                    {addon.price}
                                  </b>
                                </li>
                              );
                            })}
                          </div>
                        )}
                        {/* applied coupon display */}
                        {/* {courseDetails.couponCodeApplied === 1 && (
                          <li>
                            <span>Coupon : {courseDetails.couponCode} </span>
                            <b>
                              <BiRupee />
                              {courseDetails.couponCodeAmount}
                            </b>
                          </li>
                        )} */}
                      </ul>
                      {/* <div className="couponcode">
                        <div className="applyCoupon">
                          <b>Coupon code</b>
                          <div className="input_wrap">
                            <input
                              type="text"
                              placeholder="Enter coupon code"
                              value={coupon}
                              onChange={(e) => setCoupon(e.target.value)}
                              maxLength={20}
                            />
                            <button
                              type="button"
                              ref={buttonRef}
                              onClick={() => couponApplyHandler(coupon)}
                            >
                              Apply
                            </button>
                          </div>
                        </div> */}
                        {/* hide coupon button if coupons not available */}
                        {/* {couponList && couponList.length > 0 && (
                          <div className="couponCodeListing">
                            <p>
                              <span
                                onClick={() => setCouponToggle(!couponToggle)}
                              >
                                {couponToggle ? "Hide Coupons" : "Show Coupons"}
                              </span>
                            </p>
                            {couponToggle && (
                              <ul>
                                {couponList &&
                                  couponList?.map((couponData, index) => {
                                    return (
                                      <li
                                        className={`${
                                          couponData.couponcode?.toLowerCase() ===
                                          appliedCoupon?.toLowerCase()
                                            ? "applied"
                                            : ""
                                        }`}
                                        key={index}
                                      >
                                        <span className="coupon">
                                          {couponData.couponcode}
                                        </span>
                                        <div className="button_wrap">
                                          <button
                                            onClick={() =>
                                              couponApplyHandler(
                                                couponData.couponcode
                                              )
                                            }
                                          >
                                            {couponData.couponcode?.toLowerCase() ===
                                            appliedCoupon?.toLowerCase()
                                              ? "Applied"
                                              : "Apply"}
                                          </button>
                                          {couponData.couponcode?.toLowerCase() ===
                                            appliedCoupon?.toLowerCase() && (
                                            <>
                                              <span
                                                className="delete_coupon"
                                                onClick={() => {
                                                  getBillingCourseDetail();
                                                  setCoupon("");
                                                  setAppliedCoupon("");
                                                }}
                                              >
                                                <FaTimesCircle />
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </li>
                                    );
                                  })}
                              </ul>
                            )}
                          </div>
                        )}
                      </div> */}
                      <div className="total_amount">
                        <b>Total amount</b>
                        <b>
                          <BiRupee />{userData?.totalamount || "0.00"}
                        </b>
                      </div>

                      {/* payment update */}
                      <PaymentLinkPayment
                        isEditable={isEditable}
                        handleValidation={handleValidation}
                        userData={userData}
                        courseId={userData?.courseid}
                        courseDetails={courseDetails}
                        buttonRef={buttonRef2}
                        amtotal={userData?.totalamount}
                        studentid={userData?.studentid}
                        setButtonLoading={setButtonLoading2}
                      />
                    {/* </> */}
                  {/* )} */}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
